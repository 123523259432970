const videoOptimizations = {
    init() {
        document.querySelectorAll('.interactive-checklist, .media, .wide-interactive')?.forEach(sections => {
            const videos = sections.querySelectorAll('video');

            if (!videos.length) {
                return;
            }

            function checkVideo() {
                videos.forEach(video => {
                    const rect = video.getBoundingClientRect();
                    if (rect.top < window.innerHeight + 500 && rect.bottom >= 0) {
                        var playPromise = video.play();
                    } else {
                        if (playPromise !== undefined) {
                            playPromise.then(_ => {
                              video.pause();
                            })
                            .catch(error => {
                                console.log('Video play promise error' ,error)
                            });
                        } else {
                            video.pause();
                        }
                    }

                });
            }

            checkVideo();

            window.addEventListener('scroll', checkVideo, { passive: true });
            window.addEventListener('resize', checkVideo, { passive: true });
        });
    }
};

window.addEventListener('DOMContentLoaded', () => videoOptimizations.init())